import React from 'react'
import { Layout } from '../Dashboard/Layout'

const Files = () => {
  return (
    <Layout>
      <div>Under construction! Yet to be BUILD!</div>
    </Layout>
  )
}

export default Files